.carousel-img img {
  width: auto;
  height: 400px;
  max-height: 400px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  filter: invert(100%);
}

.carousel-control-next,
.carousel-control-prev {
  background-color: $lightgrey;
  opacity: 0.4;

  &:hover {
    opacity: 0.6;
    transition: all 0.5s ease;
  }
}