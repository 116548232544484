.cta-dya {
    width: auto;
    height: 80vh;
    padding: 1rem 0.5rem;
    display: flex;
    position: relative;
    align-items: center;
    color: $accent;
    background-image: url('/img/hero/cta.jpg');
    background-size: cover;
    background-position: center;

    @include media-breakpoint-up(md) {
        padding: 2rem;
    }
}

.cta-overlay {
    width: 100%;
    background: rgba(0,0,0,0.3);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}

.cta-text {
    color: white;
    z-index: 1000;
    padding: 0.5rem;
    margin: auto;
    text-align: center;
    font-size: 1.2rem;

    @include media-breakpoint-up(md) {
        padding: 2rem;
    }
}

.cta-button {
    width: 100%;
    padding: 0.5rem 1rem;
    background-color: transparent;
    color: white;
    transition: all 0.5s ease;
    border: 1pt solid white;
    border-radius: 4px;

    &:hover {
        background-color: white;
        transition: all 0.5s ease;
        color: $accent;
    }

    @include media-breakpoint-up(md) {
        width: 30%;
    }
}

footer {
    padding: 1rem;
}

.copyright {
    text-align: center;
    margin: 0;
}
