.container-grey {
    background-color: $lightgrey;
    padding: 1rem;
    margin: 0;
    width: 100%;

    @include media-breakpoint-up(md) {
        padding: 2rem;
    }
}

.container-white {
    background-color: white;
    padding: 1rem;
    margin: 0;
    width: 100%;

    @include media-breakpoint-up(md) {
        padding: 2rem;
    }
}

.container-grey-condensed {
    background-color: $lightgrey;
    padding: 1.5rem;
    margin: 0;
}

.container-white-condensed {
    background-color: white;
    padding: 1.5rem;
    margin: 0;
}

.project-container {
    background-color: white;
    padding: 1.5rem;
    margin: 0;

    @include media-breakpoint-up(md) {
        padding: 3rem;
    }
}