// Custom buttons

.button {
    padding: 0.5rem 1rem;
    color: $white;
    transition: all 0.5s ease;
    border: 1pt solid white;

    @include media-breakpoint-down(sm) {
        width: 100%;
    }

    &:hover {
        transition: all 0.5s ease;
    }

    &__primary {
        background-color: $primary;
        border-color: $primary;
        &:hover {
            background-color: $accent;
            border-color: $primary;
        }
        &:focus {
            background-color: $accent;
            border-color: $primary;
            box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
        }
    }

    &__secondary {
        background-color: $secondary;
        border-color: $secondary;
        &:hover {
            background-color: #5a6268;
            border-color: $secondary;
        }
        &:focus {
            background-color: #5a6268;
            border-color: #545b62;
            box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
        }
    }

    &__see-more {
        width: 100%;
        margin: 0 1rem;
        padding: 0.8rem 1.2rem;
        background-color: transparent;
        color: $primary;
        transition: all 0.5s ease;
        border: 1pt solid $primary;
        border-radius: 4px;

        &:hover {
            background-color: $primary;
            transition: all 0.5s ease;
            color: $white;
        }

        @include media-breakpoint-up(md) {
            width: 30%;
        }
    }
}