@function calculateRem($size) {
    $remSize: $size / 16px;
    @return $remSize * 1rem;
}

@mixin font-size($font-size: $font-size-base, $line-height: null, $letter-spacing: null) {
    font-size: $font-size;
    // font-size: calculateRem($font-size);
    @if $line-height { line-height: $line-height; }
    @if $letter-spacing { letter-spacing: $letter-spacing; }
}

@mixin link-clean {
    text-decoration: none;
    color: inherit;
    &:hover, &:active, &:focus {
        color: inherit;
        text-decoration: none;
    }
}

@mixin link-color($color) {
    color: $color;
    transition: all 0.2s;
    text-decoration: underline;
    &:hover, &:active, &:focus {
        color: darken($color, 10%);
    }
}

@mixin link-color-clean($color) {
    cursor: pointer;
    text-decoration: none;
    color: $color;
    transition: all 0.2s;
    &:hover, &:active, &:focus {
        color: darken($color, 10%);
        text-decoration: none;
    }
}

@mixin link-arrow($size, $color: $body-color) {
    font-weight: bold;
    display: flex;
    align-items: center;
    text-decoration: none;
    z-index: 1;
    transition: all 0.2s;
    &:before {
        // @include icon-font();
        content: $icon-chevron-right;
        color: $primary;
        transition: all 0.075s linear;
        padding-right: 7px;
        margin-left: -3px;
    }
    &:hover {
        color: $gray-800;
        &:before {
            transform: translate3d(4px, 0, 0);
        }
    }
    @if ($size == lg) {
        @include font-size(14px, 16px);
        &:before {
            font-size: 14px;
        }
        @include media-breakpoint-up(md) {
            @include font-size(18px, 20px);
        }
    } @else {
        @include font-size(12px, 16px);
        &:before {
            font-size: 12px;
        }
        @include media-breakpoint-up(md) {
            @include font-size(14px, 16px);
        }
    }
    @if ($color == white) {
        &:hover {
            color: $gray-300;
        }
    }
}

@mixin heading($size: h2, $reveal: false) {
    font-family: $font-family-sans-serif;
    &:after {
        content: '';
        display: block;
        margin-top: 13px;
        border-bottom: 2px solid $primary;
        @include media-breakpoint-up(md) {
            margin-top: 18px;
        }
    }
    @if ($size == h1) {
        @include font-size(22px, 24px);
        text-transform: uppercase;
        &:after {
            border-bottom: 3px solid $primary;
        }
        @include media-breakpoint-up(md) {
            @include font-size(36px, 36px);
        }
    }
    @if ($size == h2) {
        @include font-size(20px, 36px);
        @include media-breakpoint-up(md) {
            @include font-size(30px, 36px);
        }
    }
    @if ($reveal == true) {
        &:after {
            transition: all 0.3s ease;
            width: 0;
        }
        &.in-view {
            &:after {
                width: 50px;
            }
        }

    } @else {
        &:after {
            width: 50px;
        }
    }
}