body {
  @include media-breakpoint-up(lg) {
    background-color: $gray-100;
  }
}

#content {
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: $page-width;
    margin: 0 auto;
  }
}

:focus {
  outline: none !important;
}

// Conflicts with other title classes
// .title {
//   font-size: $title-size;
//   text-align: center;
// }

.subtitle {
  font-size: $subtitle-size;
  text-align: center;
  padding: 1rem 1rem 1.5rem;
}

.text {
    font-size: $text-size;
}

.secondary-subtitle {
  font-size: $secondary-subtitle;
  color: $accent;
  font-weight: 900;
}

.tagline {
  font-weight: 900;
  color: $accent;
  text-align: center;
  font-size: 1.5rem;

  @include media-breakpoint-up(md) {
    text-align: right;
  }
}

.home-row {
  padding: 0 25%;

  @media (max-width: 768px) {
    padding: 0 5%;
  }
}

img {
  object-fit: contain;
}

.g-recaptcha {
  @include media-breakpoint-down(sm) {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

.recaptcha-group {
  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
  }
}

.box-shadow {
  @extend .z-depth-1;
  transition: transform 300ms, filter 1s ease-in-out, box-shadow 300ms;
}

.box-shadow:hover {
  @extend .z-depth-1-light;
  transform: translate3d(0.2ch, 0.02in, 0.2em);
  cursor: pointer;
}

.box-shadow-deep {
  @extend .z-depth-3;
  transition: transform 300ms, filter 1s ease-in-out, box-shadow 300ms;
}

.box-shadow-deep:hover {
  @extend .z-depth-1;
  transform: translate3d(0.2ch, 0.02in, 0.2em);
  cursor: pointer;
}

.box-shadow-lift {
  @extend .z-depth-1;
  transition: transform 300ms, filter 1s ease-in-out, box-shadow 300ms;
}

.box-shadow-lift:hover {
  transform: translate3d(-0.2ch, -0.02in, 0.2em);
  cursor: pointer;
  -webkit-box-shadow: 4px 12px 31px -15px rgba(0,0,0,0.43);
  -moz-box-shadow: 4px 12px 31px -15px rgba(0,0,0,0.43);
  box-shadow: 4px 12px 31px -15px rgba(0,0,0,0.43);
}

.greyscale-50 {
  -webkit-filter: grayscale(50%); /* Safari 6.0 - 9.0 */
  filter: grayscale(50%);
}

.card-img-wrap {
  overflow: hidden;
  position: relative;
}
.card-img-wrap:after {
  content: '';
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
}
.card-img-wrap img {
  transition: transform 3s, filter 3s ease-in-out;
  width: 100%;
}
.card-img-wrap:hover img {
  transform: scale(1.1);
}

// Contact page details
.contact-wrapper {
  display: block;

  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: space-between;
  }

  .contact-details {
    &:not(:last-child) {
      padding: 0 0 1rem;

      @include media-breakpoint-up(sm) {
        padding-right: 1rem;
      }
    }

    p {
      margin-bottom: 0;
    }
  }
}

// Recognition images on home
.recognition-img {
  margin: 1rem 0;

  @include media-breakpoint-up(md) {
    margin: 0;
  }
}

.see-more {
  background-color: $lightgrey;
  font-size: 1.2rem;
  padding-bottom: 2rem;
}

// Scroll up
.scroll-up {
  transform: translateY(-80px);
}

// Z-levels
.z-depth-0 {
  box-shadow: none !important;
}

/* 2dp elevation modified*/
.z-depth-1 {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),
              0 3px 1px -2px rgba(0,0,0,0.12),
              0 1px 5px 0 rgba(0,0,0,0.2);
}

.z-depth-1-light {
  box-shadow: 0 0.7px 0.7px 0 rgba(0,0,0,0.14),
              0 1.2px 0.2px -4px rgba(0,0,0,0.12),
              0 0.3px 2px 0 rgba(0,0,0,0.2);
}

/* 6dp elevation modified*/
.z-depth-2 {
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14),
              0 1px 10px 0 rgba(0,0,0,0.12),
              0 2px 4px -1px rgba(0,0,0,0.3);
}

/* 12dp elevation modified*/
.z-depth-3 {
  box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14),
              0 3px 14px 2px rgba(0,0,0,0.12),
              0 5px 5px -3px rgba(0,0,0, 0.2);
}

/* 16dp elevation */
.z-depth-4 {
  box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14),
              0 6px 30px 5px rgba(0,0,0,0.12),
              0 8px 10px -7px rgba(0,0,0,0.2);
}

/* 24dp elevation */
.z-depth-5 {
  box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14),
              0 9px 46px 8px rgba(0,0,0,0.12),
              0 11px 15px -7px rgba(0,0,0,0.2);
}

div[data-page="recognition"] {
  .recognition-img-wrapper {
    padding: 0;
  }

  .nGY2GThumbnailImage {
    background-size: contain !important;
    background: $lightgrey;
  }
}
