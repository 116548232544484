@function z($name) {
    @if index($z-indexes, $name) {
        @return (length($z-indexes) - index($z-indexes, $name)) + 1;
    } @else {
        @warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';
        @return null;
    }
}

@mixin z-index($name) {
    z-index: z($name);
}

@mixin absolute-fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@mixin list-unstyled {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
}

@mixin background-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

@mixin hr-after {
    &:after {
        display: block;
        margin-top: 13px;
        content: '';
        width: 50px;
        border-bottom: 2px solid $primary;
        @include media-breakpoint-up(md) {
            margin-top: 18px;
        }
    }
}