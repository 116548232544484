.card-img-top-wrapper {
    @extend .p-2, .d-flex, .align-self-stretch, .col-sm-6, .col-lg-4;

    .card {
        width: 100%;
    }
}

.card-img-overlay-wrapper {
    @extend .col-md-3, .mx-auto;
}

.card-img-overlay-dya {
    @extend .mx-auto;

    img {
        max-width: 100%;

        @include media-breakpoint-up(md) {
            max-width: 700px;
        }
    }
}

.card-img-top {
    width: 100%;
    height: 50vw;
    object-fit: cover;
    @include media-breakpoint-up(sm) {
        height: 30vw;
    }
    @include media-breakpoint-up(lg) {
        height: 20vw;
    }
}

.recognition-card {
    width: 100%;
    margin: 1rem;

    @include media-breakpoint-up(md) {
        width: 25%;
    }
}
