$component: 'hero';
.#{$component} {
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    min-height: 88vh;

    &__overlay {
        width: 100%;
        background: rgba(0,0,0,0.3);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
    }

    &__image {
        @include background-image;
        @include absolute-fill;
        z-index: 0;
    }

    &__content {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
    }

    &__heading {
        @include heading(h1, true);
        @include font-size(25px, 29px);
        margin: 0;
        @include media-breakpoint-up(md) {
            @include font-size(36px, 1);
        }
    }

    &__text {
        @include font-size(15px, 27px);
        letter-spacing: 1px;
        padding: 20px;
        @include media-breakpoint-up(md) {
            padding: 0px 20px;
            @include font-size(18px, 30px);
        }
    }

    &__anchors {
        font-size: 20px;
        color: white;
        text-decoration: none;
        display: inline-block;
        position: static;

        a {
            color: white;
            cursor: pointer;
            &:hover {
                color: $secondary;
            }
        }
    }
}